import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";

const Footer = () => {
return (
	<Box>
	<h1 style={{ color: "#16DAFB",
				textAlign: "center",
				marginTop: "-50px" }}>
		Tic Tac Toe
	</h1>
	<FooterLink href="https://webdesignbyamir.com/">
	Web Design By Amir
	</FooterLink>
	
	</Box>
);
};
export default Footer;
